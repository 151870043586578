/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//! Fixing Angular Universal
// .ion-page-invisible {
//   opacity: 1 !important;
// }

// .ion-page {
//   overflow: auto !important;
// }

//! Fixing ion-modal backdrop
ion-modal {
  --backdrop-opacity: 0.5 !important;
}

//! Important
// Fixing unclickable menu
ion-item[button],
.menu-content-open {
  pointer-events: auto !important;
}

//! Fixing missing menu button
// .menu-button-hidden {
//   display: unset !important;
// }

//! Hide disabled ion-select-option
.alert-radio-group {
  button[disabled] {
    display: none;
  }
}

//! Fix ion-item line padding for android
.fix-line-padding.md {
  --inner-padding-start: 1rem;
  --padding-start: 0;
}

//! Fix unscrollable text-area
.native-textarea {
  overflow: auto !important;
}

section {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

.flex-middle-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.middle-card {
  width: 500px;
  max-width: 95%;
}

.icon-md {
  font-size: 2.5rem;
}

.icon-lg {
  font-size: 5rem;
}

.icon-btn {
  cursor: pointer;
  margin: 0.5rem;
}

.primary {
  color: var(--ion-color-primary);
}
.secondary {
  color: var(--ion-color-secondary);
}
.success {
  color: var(--ion-color-success);
}
.danger {
  color: var(--ion-color-danger);
}
.warning {
  color: var(--ion-color-warning);
}
.medium {
  color: var(--ion-color-medium);
}
.light {
  color: var(--ion-color-light);
}
.dark {
  color: var(--ion-color-dark);
}

.full-width {
  width: 100%;
}

ion-item-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: 1px !important;
}

.true {
  color: var(--ion-color-success);
}

.false {
  color: var(--ion-color-danger);
}

.warning {
  color: var(--ion-color-warning);
}

.secondary {
  color: var(--ion-color-secondary);
}

.menu-icon {
  padding-left: 1rem;
  font-size: 1.4rem;
  display: flex;
  color: var(--ion-color-primary);
}

@media (min-width: 769px) {
  .menu-icon {
    display: none;
  }
}

@media (max-width: 768px) {
  .menu-icon {
    padding-left: 0;
  }
}

.menu-show {
  left: 0 !important;
  transition: all 0.2s;
}

.menu-hide {
  left: -100% !important;
  transition: all 0.2s;
}

@media (min-width: 769px) {
  .menu-hide {
    left: 0 !important;
  }
}

.inline {
  display: inline !important;
}

.img-round {
  border-radius: 0.5rem;
  overflow: hidden;
}

.no-padding {
  padding: 0;
  --inner-padding: 0;
  --padding: 0;
}

.margin-auto {
  margin: auto;
}

@media (max-width: 500) {
  .no-padding-on-mobile {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.title {
  font-size: large;
  font-weight: bold;
  display: block;
}

.pointer {
  cursor: pointer;
}

ion-item.selected {
  --background: var(--ion-color-light-tint);
}

.no-background {
  background: none;
  --background: none;
}

.float-right {
  float: right;
}

thead {
  background-color: var(--ion-color-primary-shade);
  th,td {
    background-color: var(--ion-color-primary-shade);
    color: var(--ion-color-primary-contrast);
  }
}

ion-card,
ion-list {
  box-shadow: 0px 0px 5px 0px var(--ion-color-card-shadow);
}

ion-title {
  color: var(--ion-color-primary-shade);
}

[aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: var(--ion-color-dark);
}

.sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: var(--ion-color-dark);
}

.search-icon {
  color: var(--ion-color-dark);
  opacity: 0.5;
}

ion-item {
  --border-color: var(--ion-color-light-shade);
}

.alert-checkbox-label.sc-ion-alert-md {
  color: var(--ion-color-dark);
}

ion-card {
  color: var(--ion-color-dark);
}

.item {
  contain: none;
}

.link {
  color: var(--ion-color-primary);
  text-decoration: underline;
  cursor: pointer;
}