/** Ionic CSS Variables **/
body {
  --ion-color-primary: #00adb5;
  --ion-color-primary-rgb: 0, 173, 181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00989f;
  --ion-color-primary-tint: #1ab5bc;
  --ion-color-secondary: #222831;
  --ion-color-secondary-rgb: 34, 40, 49;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1e232b;
  --ion-color-secondary-tint: #383e46;
  --ion-color-tertiary: #393e46;
  --ion-color-tertiary-rgb: 57, 62, 70;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #32373e;
  --ion-color-tertiary-tint: #4d5159;
  --ion-color-success: #35a762;
  --ion-color-success-rgb: 53, 167, 98;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2f9356;
  --ion-color-success-tint: #49b072;
  --ion-color-warning: #dba906;
  --ion-color-warning-rgb: 219, 169, 6;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c19505;
  --ion-color-warning-tint: #dfb21f;
  --ion-color-danger: #c12f42;
  --ion-color-danger-rgb: 193, 47, 66;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #aa293a;
  --ion-color-danger-tint: #c74455;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #222831;
  --ion-color-light-rgb: 34, 40, 49;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e232b;
  --ion-color-light-tint: #383e46;
  --ion-color-dark: #eeeeee;
  --ion-color-dark-rgb: 238, 238, 238;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d1d1d1;
  --ion-color-dark-tint: #f0f0f0;
  --ion-background-color: var(--ion-color-light);
  --ion-color-card-shadow: var(--ion-color-secondary);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);
}

/** Ionic CSS Variables **/
body.theme-pastel {
  --ion-color-primary: #bea59b;
  --ion-color-primary-rgb: 231, 200, 188;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #ac948a;
  --ion-color-primary-tint: #dbc1b6;
  --ion-color-secondary: #c2ccbc;
  --ion-color-secondary-rgb: 194, 204, 188;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #abb4a5;
  --ion-color-secondary-tint: #c8d1c3;
  --ion-color-tertiary: #939789;
  --ion-color-tertiary-rgb: 147, 151, 137;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #818579;
  --ion-color-tertiary-tint: #9ea195;
  --ion-color-success: #7fa48a;
  --ion-color-success-rgb: 127, 164, 138;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #709079;
  --ion-color-success-tint: #8cad96;
  --ion-color-warning: #ece1bb;
  --ion-color-warning-rgb: 236, 225, 187;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d0c6a5;
  --ion-color-warning-tint: #eee4c2;
  --ion-color-danger: #c0828a;
  --ion-color-danger-rgb: 192, 130, 138;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #a97279;
  --ion-color-danger-tint: #c68f96;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-background-color: #faf4ed;
  --ion-color-card-shadow: var(--ion-color-tertiary);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);
}

/** Ionic CSS Variables **/
body.theme-professional {
  --ion-color-primary: #453c67;
  --ion-color-primary-rgb: 69, 60, 103;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3d355b;
  --ion-color-primary-tint: #585076;
  --ion-color-secondary: #6d67e4;
  --ion-color-secondary-rgb: 109, 103, 228;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #605bc9;
  --ion-color-secondary-tint: #7c76e7;
  --ion-color-tertiary: #46c2cb;
  --ion-color-tertiary-rgb: 70, 194, 203;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #3eabb3;
  --ion-color-tertiary-tint: #59c8d0;
  --ion-color-success: #38b75e;
  --ion-color-success-rgb: 56, 183, 94;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #31a153;
  --ion-color-success-tint: #4cbe6e;
  --ion-color-warning: #f2f7a1;
  --ion-color-warning-rgb: 242, 247, 161;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d5d98e;
  --ion-color-warning-tint: #f3f8aa;
  --ion-color-danger: #bf2237;
  --ion-color-danger-rgb: 191, 34, 55;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a81e30;
  --ion-color-danger-tint: #c5384b;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-background-color: #ffffff;
  --ion-color-card-shadow: var(--ion-color-medium);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);
}

/** Ionic CSS Variables **/
body.theme-retro {
  --ion-color-primary: #3C6255;
  --ion-color-primary-rgb: 60,98,85;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #35564b;
  --ion-color-primary-tint: #507266;
  --ion-color-secondary: #61876E;
  --ion-color-secondary-rgb: 97,135,110;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #557761;
  --ion-color-secondary-tint: #71937d;
  --ion-color-tertiary: #A6BB8D;
  --ion-color-tertiary-rgb: 166,187,141;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #92a57c;
  --ion-color-tertiary-tint: #afc298;
  --ion-color-success: #35a762;
  --ion-color-success-rgb: 53,167,98;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #2f9356;
  --ion-color-success-tint: #49b072;
  --ion-color-warning: #dba906;
  --ion-color-warning-rgb: 219,169,6;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #c19505;
  --ion-color-warning-tint: #dfb21f;
  --ion-color-danger: #c12f42;
  --ion-color-danger-rgb: 193,47,66;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #aa293a;
  --ion-color-danger-tint: #c74455;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #EAE7B1;
  --ion-color-light-rgb: 234,231,177;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #cecb9c;
  --ion-color-light-tint: #ece9b9;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-background-color: var(--ion-color-light);
  --ion-color-card-shadow: var(--ion-color-medium);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);
}

/** Ionic CSS Variables **/
body.theme-night-ocean {
  --ion-color-primary: #00adb5;
  --ion-color-primary-rgb: 0, 173, 181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00989f;
  --ion-color-primary-tint: #1ab5bc;
  --ion-color-secondary: #222831;
  --ion-color-secondary-rgb: 34, 40, 49;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1e232b;
  --ion-color-secondary-tint: #383e46;
  --ion-color-tertiary: #393e46;
  --ion-color-tertiary-rgb: 57, 62, 70;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #32373e;
  --ion-color-tertiary-tint: #4d5159;
  --ion-color-success: #35a762;
  --ion-color-success-rgb: 53, 167, 98;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2f9356;
  --ion-color-success-tint: #49b072;
  --ion-color-warning: #dba906;
  --ion-color-warning-rgb: 219, 169, 6;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c19505;
  --ion-color-warning-tint: #dfb21f;
  --ion-color-danger: #c12f42;
  --ion-color-danger-rgb: 193, 47, 66;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #aa293a;
  --ion-color-danger-tint: #c74455;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #222831;
  --ion-color-light-rgb: 34, 40, 49;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e232b;
  --ion-color-light-tint: #383e46;
  --ion-color-dark: #eeeeee;
  --ion-color-dark-rgb: 238, 238, 238;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d1d1d1;
  --ion-color-dark-tint: #f0f0f0;
  --ion-background-color: var(--ion-color-light);
  --ion-color-card-shadow: var(--ion-color-secondary);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);
  --ion-item-background: var(--ion-color-light-shade);
}