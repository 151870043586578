// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
body.theme-retro {
  --ion-color-primary: #3C6255;
	--ion-color-primary-rgb: 60,98,85;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #35564b;
	--ion-color-primary-tint: #507266;

	--ion-color-secondary: #61876E;
	--ion-color-secondary-rgb: 97,135,110;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #557761;
	--ion-color-secondary-tint: #71937d;

	--ion-color-tertiary: #A6BB8D;
	--ion-color-tertiary-rgb: 166,187,141;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #92a57c;
	--ion-color-tertiary-tint: #afc298;

	--ion-color-success: #35a762;
	--ion-color-success-rgb: 53,167,98;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #2f9356;
	--ion-color-success-tint: #49b072;

	--ion-color-warning: #dba906;
	--ion-color-warning-rgb: 219,169,6;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #c19505;
	--ion-color-warning-tint: #dfb21f;

	--ion-color-danger: #c12f42;
	--ion-color-danger-rgb: 193,47,66;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #aa293a;
	--ion-color-danger-tint: #c74455;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #EAE7B1;
	--ion-color-light-rgb: 234,231,177;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #cecb9c;
	--ion-color-light-tint: #ece9b9;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-background-color: var(--ion-color-light);
  --ion-color-card-shadow: var(--ion-color-medium);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);
}

