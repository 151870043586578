// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
body.theme-professional {
  --ion-color-primary: #453c67;
  --ion-color-primary-rgb: 69, 60, 103;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3d355b;
  --ion-color-primary-tint: #585076;

  --ion-color-secondary: #6d67e4;
  --ion-color-secondary-rgb: 109, 103, 228;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #605bc9;
  --ion-color-secondary-tint: #7c76e7;

  --ion-color-tertiary: #46c2cb;
  --ion-color-tertiary-rgb: 70, 194, 203;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #3eabb3;
  --ion-color-tertiary-tint: #59c8d0;

  --ion-color-success: #38b75e;
  --ion-color-success-rgb: 56, 183, 94;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #31a153;
  --ion-color-success-tint: #4cbe6e;

  --ion-color-warning: #f2f7a1;
  --ion-color-warning-rgb: 242, 247, 161;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d5d98e;
  --ion-color-warning-tint: #f3f8aa;

  --ion-color-danger: #bf2237;
  --ion-color-danger-rgb: 191, 34, 55;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a81e30;
  --ion-color-danger-tint: #c5384b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #ffffff;
  --ion-color-card-shadow: var(--ion-color-medium);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);
}

