// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
body.theme-pastel {
  --ion-color-primary: #bea59b;
  --ion-color-primary-rgb: 231, 200, 188;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #ac948a;
  --ion-color-primary-tint: #dbc1b6;

  --ion-color-secondary: #c2ccbc;
  --ion-color-secondary-rgb: 194, 204, 188;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #abb4a5;
  --ion-color-secondary-tint: #c8d1c3;

  --ion-color-tertiary: #939789;
  --ion-color-tertiary-rgb: 147, 151, 137;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #818579;
  --ion-color-tertiary-tint: #9ea195;

  --ion-color-success: #7fa48a;
  --ion-color-success-rgb: 127, 164, 138;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #709079;
  --ion-color-success-tint: #8cad96;

  --ion-color-warning: #ece1bb;
  --ion-color-warning-rgb: 236, 225, 187;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d0c6a5;
  --ion-color-warning-tint: #eee4c2;

  --ion-color-danger: #c0828a;
  --ion-color-danger-rgb: 192, 130, 138;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #a97279;
  --ion-color-danger-tint: #c68f96;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #faf4ed;
  --ion-color-card-shadow: var(--ion-color-tertiary);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);
}

