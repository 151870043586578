// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
body.theme-night-ocean {
  --ion-color-primary: #00adb5;
  --ion-color-primary-rgb: 0, 173, 181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00989f;
  --ion-color-primary-tint: #1ab5bc;

  --ion-color-secondary: #222831;
  --ion-color-secondary-rgb: 34, 40, 49;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1e232b;
  --ion-color-secondary-tint: #383e46;

  --ion-color-tertiary: #393e46;
  --ion-color-tertiary-rgb: 57, 62, 70;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #32373e;
  --ion-color-tertiary-tint: #4d5159;

  --ion-color-success: #35a762;
  --ion-color-success-rgb: 53, 167, 98;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2f9356;
  --ion-color-success-tint: #49b072;

  --ion-color-warning: #dba906;
  --ion-color-warning-rgb: 219, 169, 6;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c19505;
  --ion-color-warning-tint: #dfb21f;

  --ion-color-danger: #c12f42;
  --ion-color-danger-rgb: 193, 47, 66;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #aa293a;
  --ion-color-danger-tint: #c74455;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #222831;
  --ion-color-light-rgb: 34, 40, 49;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e232b;
  --ion-color-light-tint: #383e46;

  --ion-color-dark: #eeeeee;
  --ion-color-dark-rgb: 238, 238, 238;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d1d1d1;
  --ion-color-dark-tint: #f0f0f0;

  --ion-background-color: var(--ion-color-light);
  --ion-color-card-shadow: var(--ion-color-secondary);
  --highlight-color-focused: var(--ion-color-primary-shade);
  --highlight-color-valid: var(--ion-color-success);
  --highlight-color-invalid: var(--ion-color-danger);
  --ion-text-color: var(--ion-color-dark);

  //Dark mode only
  --ion-item-background: var(--ion-color-light-shade);
}